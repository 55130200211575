import React from 'react'
import '../Layout/styles.css'
import './styles.css'

const Masthead = ({ heading, subheading, image }) => (
  <div className="masthead card">
    <div className="masthead__info">
      <div className="masthead__info-left">
        <h1 className="masthead__heading">{heading}</h1>
        <div className="masthead__sub-heading">{subheading}</div>
      </div>
      <img alt={heading} className="masthead__img" src={image} />
    </div>
  </div>
)

export default Masthead
