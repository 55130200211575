import React from 'react'
import Layout from '../components/Layout'
import Masthead from '../components/Masthead'
import Grid from '../components/Grid'
import Seo from '../components/Seo'
import Metatags from '../components/Metatags'

const IndexPage = () => (
  <Layout>
    <Seo
      title="How it works"
      meta={[
        {
          name: 'description',
          content:
            'We all love travelling and we often have a skill or two we could easily share, so why not mix both? Become a captain and lead travelers to interesting destinations, new adventures, and assist them in coordinating the essentials.',
        },
      ]}
    />
    <Metatags
      title="How it Works"
      description="We all love travelling and we often have a skill or two we could easily share, so why not mix both? Become a captain and lead travelers to interesting destinations, new adventures, and assist them in coordinating the essentials."
    />
    <Masthead
      image="/bonding.jpg"
      heading="Adventure the world"
      subheading="Skill Sailors allows you to group multiple trips into longer term voyages so other digital nomads can experience them with you. "
    />
    <Grid
        heading="Join a voyage"
        content="Enjoy the sense of community that is created among like minded individuals while you cowork and travel the world."
        image="/woman-cowork.jpg"
        icon1="/networking.svg"
        bulletHeading1="CoWorking"
        bulletContent1="All of our voyages are centered around coworking, so you can ensure that you are surrounded by productive fellow travellers."
        icon2="/bed.svg"
        bulletHeading2="Accomodation"
        bulletContent2="Voyages come with pre-planned accomodation located near or with fellow travellers, this way you feel connected and safe on your travels."
        icon3="/adventurer.svg"
        bulletHeading3="Adventures"
        bulletContent3="Pre-planned activities and adventures (outside work hours of course!) will make sure you don't forget to have fun on your co-working trip."
      />
      <Grid
        heading="Start your own voyage"
        content="If you think you travel best as a leader, sign up and plan your own voyage on Skillsailors!"
        image="/sunset.jpg"
        icon1="/calendar.svg"
        bulletHeading1="Plan your adventure"
        bulletContent1="Skillsailors gives you the ability to order your trips by date, display them on a map, and list accomodation and activities"
        icon2="/expenses.svg"
        bulletHeading2="Share expenses"
        bulletContent2="Add a fixed monthly fee to your coworking adventures so budget-minded travellers know what costs to expect."
        icon3="/blog.svg"
        bulletHeading3="Tell your story"
        bulletContent3="Add pictures, video, and blogs to each trip as the voyage progresses"
      />
  </Layout>
)

export default IndexPage
