 import React from 'react'
import '../Layout/styles.css'
import './styles.css'

const Grid = ({ heading, content, image, bulletHeading1, bulletHeading2, bulletHeading3, bulletContent1, bulletContent2, bulletContent3, icon1, icon2, icon3 }) => (
  <div className="grid-wrapper">
    <div>
      <h2 className="grid-block__left-heading">{heading}</h2>
      <p className="">{content}</p>
      <img alt={heading} className="grid-block__left-img" src={image}/>
    </div>
    <div>
      <div className="grid-block__right-wrapper">
        <img alt={bulletHeading1} className="grid-block__right-icon" src={icon1}/>
        <div className="grid-block__right-content">
          <div className="grid-block__right-heading">{bulletHeading1}</div>
          <div className="grid-block__right-text">{bulletContent1}</div>
        </div>
      </div>
      <div className="grid-block__right-wrapper">
        <img alt={bulletHeading2} className="grid-block__right-icon" src={icon2}/>
        <div className="grid-block__right-content">
          <div className="grid-block__right-heading">{bulletHeading2}</div>
          <div className="grid-block__right-text">{bulletContent2}</div>
        </div>
      </div>
      <div className="grid-block__right-wrapper">
        <img alt={bulletHeading3} className="grid-block__right-icon" src={icon3}/>
        <div className="grid-block__right-content">
          <div className="grid-block__right-heading">{bulletHeading3}</div>
          <div className="grid-block__right-text">{bulletContent3}</div>
        </div>
      </div>
    </div>
  </div>
)

export default Grid
